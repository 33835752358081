
import { defineComponent, ref, onMounted, watch } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  name: 'TmProgress',
  components: {
    TmTooltip,
  },
  props: {
    size: {
      type: String,
      default: '9px',
    },
    color: {
      type: String,
    },
    value: {
      type: Number,
      default: 0,
    },
    extraRounded: {
      type: Boolean,
    },
    topRounded: {
      type: Boolean,
    },
    bottom: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
    },
    tooltipState: {
      type: Boolean,
    },
    showProgress: {
      type: Boolean,
    },
    darkBg: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const hasTooltip = !!context.slots.tooltip
    const hasText = !!context.slots.default
    const track = ref()
    const barText = ref()
    const textRight = ref(false)
    const isRender = ref(false)

    onMounted(async () => {
      if ((hasText || props.showProgress) && barText.value.clientWidth > track.value.clientWidth * props.value) textRight.value = true

      await new Promise((resolve) => setTimeout(resolve, 0))
      isRender.value = true
    })

    watch(() => props.value, (val) => {
      if ((hasText || props.showProgress) && barText.value.clientWidth > track.value.clientWidth * val) {
        textRight.value = true
      } else {
        textRight.value = false
      }
    })

    return {
      hasTooltip,
      hasText,
      track,
      barText,
      textRight,
      isRender,
    }
  },
})
