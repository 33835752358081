// eslint-disable-next-line
import vars from '!!sass-vars-to-js-loader!../styles/custom-variables.scss'

export const styleVars = vars

type RgbaKeys = 'r' | 'g' | 'b' | 'a'
export type Rgba = Record<RgbaKeys, number> & { rgba: string }

const componentToHex = (c: number) => {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (rgb: Rgba): string => {
  return '#' + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b)
}
