import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7141703d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-file", {
      [`tm-file--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      'tm-file--outline': _ctx.outline,
    }]),
    style: _normalizeStyle({background: _ctx.getFile.background, borderColor: _ctx.getFile.borderColor})
  }, [
    (_ctx.showPreview)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "tm-file__preview",
          src: _ctx.url
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_component_tm_icon, {
          key: 1,
          style: _normalizeStyle({color: _ctx.getFile.color}),
          name: _ctx.getFile.icon
        }, null, 8, ["style", "name"]))
  ], 6))
}