import { ref } from 'vue'

export const loadFile = (afterLoadFunc?: () => void): any => {
  const uploadingPercentage = ref(0)
  const uploadingStatus = ref(false)

  const uploadingProgress = () => {
    uploadingStatus.value = true

    const interval = setInterval(() => {
      if (uploadingPercentage.value >= 100) {
        uploadingPercentage.value = 0
        uploadingStatus.value = false
        if (afterLoadFunc) {
          afterLoadFunc()
        }
        clearInterval(interval)
      } else {
        uploadingPercentage.value += 5
      }
    }, 100)
  }

  return {
    uploadingPercentage,
    uploadingStatus,
    uploadingProgress,
  }
}
