
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import { styleVars, rgbToHex } from '@/compositions/styleVariables'
import _ from 'lodash'
import type { SizeProp, TmFileExtension } from '@/definitions/shared/types'

type FileFormatMapType = { [key: string]: TmFileExtension }
type GetFileType = {
  icon: string;
  color: string;
  background: string;
  borderColor: string;
}

export const fileFormatMap: FileFormatMapType = {
  doc: 'doc',
  docx: 'doc',
  odt: 'doc',
  xls: 'xls',
  xlsx: 'xls',
  xlsm: 'xls',
  ods: 'xls',
  numbers: 'xls',
  csv: 'xls',
  pdf: 'pdf',
  txt: 'text',
  rtf: 'text',
  png: 'image',
  jpg: 'image',
  jpeg: 'image',
  gif: 'image',
  svg: 'image',
  psd: 'image',
  ai: 'image',
  tiff: 'image',
  ico: 'image',
  zip: 'archive',
  rar: 'archive',
  '7z': 'archive',
  json: 'json',
  aif: 'audio',
  aiff: 'audio',
  aifc: 'audio',
  aifr: 'audio',
  cda: 'audio',
  mid: 'audio',
  midi: 'audio',
  mp3: 'audio',
  mpa: 'audio',
  ogg: 'audio',
  wav: 'audio',
  wma: 'audio',
  wpl: 'audio',
  '3g2': 'video',
  '3gp': 'video',
  avi: 'video',
  flv: 'video',
  h264: 'video',
  m4v: 'video',
  mkv: 'video',
  mov: 'video',
  mp4: 'video',
  mpg: 'video',
  rm: 'video',
  swf: 'video',
  vob: 'video',
  wmv: 'video',
  key: 'presentation',
  odp: 'presentation',
  pps: 'presentation',
  ppt: 'presentation',
  pptx: 'presentation',
  email: 'mail',
  eml: 'mail',
  emlx: 'mail',
  msg: 'mail',
  oft: 'mail',
  ost: 'mail',
  pst: 'mail',
  vcf: 'mail',
  html: 'html',
}

export default defineComponent({
  name: 'TmFile',
  props: {
    file: {
      type: String as PropType<TmFileExtension>,
    },
    format: {
      type: String,
    },
    url: {
      type: String,
    },
    size: {
      type: String as SizeProp<'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'>,
      default: '',
    },
    outline: {
      type: Boolean,
    },
  },
  setup(props) {
    const fileFromFormat = computed(() => {
      if (!props.format) { return }
      if (Object.keys(fileFormatMap).includes(props.format)) { return fileFormatMap[props.format] }
      return 'unknown'
    })

    const file = computed((): TmFileExtension | undefined => fileFromFormat.value || props.file)
    const getFileObject = (icon: string, color: string, background: string, borderColor: string): GetFileType => {
      return { icon: icon, color: color, background: background, borderColor: borderColor }
    }

    const getFile = computed((): GetFileType => {
      switch (file.value) {
        case 'audio': return getFileObject('tmi-audio', rgbToHex(styleVars.red700), rgbToHex(styleVars.red100), rgbToHex(styleVars.red200))
        case 'doc': return getFileObject('tmi-doc', rgbToHex(styleVars.blue700), rgbToHex(styleVars.blue100), rgbToHex(styleVars.blue200))
        case 'json': return getFileObject('tmi-json', rgbToHex(styleVars.purple700), rgbToHex(styleVars.purple100), rgbToHex(styleVars.purple200))
        case 'mail': return getFileObject('tmi-mail', rgbToHex(styleVars.mint700), rgbToHex(styleVars.mint100), rgbToHex(styleVars.mint200))
        case 'pdf': return getFileObject('tmi-pdf', rgbToHex(styleVars.red700), rgbToHex(styleVars.red100), rgbToHex(styleVars.red200))
        case 'image': return getFileObject('tmi-png', rgbToHex(styleVars.purple700), rgbToHex(styleVars.purple100), rgbToHex(styleVars.purple200))
        case 'presentation': return getFileObject('tmi-ppt', rgbToHex(styleVars.crimson700), rgbToHex(styleVars.red100), rgbToHex(styleVars.red200))
        case 'text': return getFileObject('tmi-text', rgbToHex(styleVars.gray700), rgbToHex(styleVars.gray100), rgbToHex(styleVars.gray200))
        case 'video': return getFileObject('tmi-video', rgbToHex(styleVars.red700), rgbToHex(styleVars.red100), rgbToHex(styleVars.red200))
        case 'xls': return getFileObject('tmi-xls', rgbToHex(styleVars.green700), rgbToHex(styleVars.green100), rgbToHex(styleVars.green200))
        case 'archive': return getFileObject('tmi-zip', rgbToHex(styleVars.orange700), rgbToHex(styleVars.orange100), rgbToHex(styleVars.orange200))
        case 'html': return getFileObject('code', rgbToHex(styleVars.purple700), rgbToHex(styleVars.purple100), rgbToHex(styleVars.purple100))
        default: return getFileObject('insert_drive_file', rgbToHex(styleVars.gray700), rgbToHex(styleVars.gray100), rgbToHex(styleVars.gray100))
      }
    })

    const showPreview = computed(() => {
      return file.value && file.value === 'image' && props.url
    })

    return {
      getFile,
      showPreview,
      kebabCase: _.kebabCase,
    }
  },
})
