
import { defineComponent } from 'vue'
import { filesVariant } from '@/definitions/_general/_data/filesVariant'
import TmFile from '@/components/shared/TmFile.vue'
import TmAttachment from '@/components/shared/TmAttachment.vue'

export default defineComponent({
  components: {
    TmAttachment,
    TmFile,
  },
  setup() {
    const avatarUrl = 'https://randomuser.me/api/portraits/women/33.jpg'
    const files = filesVariant
    const sizes = ['xxSmall', 'xSmall', 'small', 'medium', 'large', 'xLarge']
    const attachment = {
      title: 'textmagic',
      extension: 'svg',
      size: '24 KB',
    }

    return {
      attachment,
      avatarUrl,
      files,
      sizes,
    }
  },
})
