export const filesVariant = [
  'doc',
  'xls',
  'pdf',
  'text',
  'image',
  'archive',
  'json',
  'audio',
  'video',
  'presentation',
  'mail',
  'html',
  'unknown',
] as const
