import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75c64fd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-progress__tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-end flex-grow-1 align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-progress", {
      'tm-progress--extra-rounded': _ctx.extraRounded,
      'tm-progress--bottom': _ctx.bottom,
      'tm-progress--top-rounded': _ctx.topRounded,
      'tm-progress--dark-background': _ctx.darkBg,
    }])
  }, [
    (_ctx.hasTooltip || _ctx.tooltipText)
      ? (_openBlock(), _createBlock(_component_tm_tooltip, {
          key: 0,
          "model-value": _ctx.tooltipState,
          "max-width": "250px",
          white: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "tooltip", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.tooltipText), 1)
              ], true)
            ])
          ]),
          _: 3
        }, 8, ["model-value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "track",
      class: "tm-progress__track",
      style: _normalizeStyle({
        height: _ctx.size,
        color: _ctx.color
      })
    }, [
      _createElementVNode("div", {
        ref: "bar",
        class: "tm-progress__bar",
        style: _normalizeStyle({
          width: _ctx.value * 100 + '%'
        })
      }, [
        _createElementVNode("div", {
          ref: "barText",
          class: _normalizeClass(["tm-progress__text", {
            'tm-progress__text--right': _ctx.textRight,
            'tm-progress__text--transition': _ctx.isRender,
          }])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.showProgress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.value * 100 + '%'), 1)
                ]))
              : _createCommentVNode("", true)
          ], true)
        ], 2)
      ], 4)
    ], 4)
  ], 2))
}