
import { defineComponent } from 'vue'
import TmFile from '@/components/shared/TmFile.vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import { loadFile } from '@/compositions/loadFile'

export default defineComponent({
  components: {
    TmEllipsis,
    TmButton,
    TmFile,
    TmProgress,
  },
  props: {
    removable: {
      type: Boolean,
    },
    downloadable: {
      type: Boolean,
    },
    widget: {
      type: Boolean,
    },
    extension: {
      type: String,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    note: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
  },
  emits: ['download', 'remove'],
  setup(props) {
    const { uploadingPercentage, uploadingStatus, uploadingProgress } = loadFile()

    if (props.loading) {
      uploadingProgress()
    }

    return {
      uploadingPercentage,
      uploadingStatus,
    }
  },
})
